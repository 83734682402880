import React from 'react'
import { Layout } from '../components/LayoutLanding'
import { Links } from '../components/Links'
import { LandingTopbar } from '../components/LandingTopbar'
import { SEO } from '../components/SEO'

const Privacy = () => (
  <Layout>
    <SEO
      title="Vanna | Política de Privacidade"
      tags={[
        'Cobranças simples',
        'Cobranças recorrentes',
        'Segurança',
        'Pix',
        'Rapidez',
        'Receba em segundos',
      ]}
      canonical="https://vanna.app/politica-privacidade/"
    />
    <LandingTopbar />
    <Links />
    <section className="my-16 px-5 md:px-8 lg:px-16">
      <div className="md:max-w-3xl md:mx-auto md:text-center mb-20">
        <h1 className="text-gray-900 text-3xl leading-9 font-heading font-medium lg:text-4xl">
          Política de privacidade.
        </h1>
        <p className="my-4 text-gray-800 leading-6 sm:text-xl lg:text-lg xl:text-xl">
          Verifique abaixo nossa política de privacidade que você aceita ao
          utilizar a plataforma.
        </p>
        <div className="flex justify-center">
          <img className="h-12 w-auto" src="/images/logo-s.png" alt="Logo" />
        </div>
      </div>

      <p className="pb-4">Última atualização em 06/03/2021</p>
      <h2 className="my-4 font-bold">Introdução</h2>
      <p className="pb-4">
        A política de privacidade tem como objetivo fornecer informações e
        estabelecer regras, de acordo com a Lei 13.709/18 e legislação vigente,
        com relação ao tratamento de dados, referentes a operações de acesso,
        armazenamento, processamento, reprodução, transferência,
        compartilhamento, utilização, eliminação, controle, modificação,
        comunicação e difusão dos dados coletados.
      </p>
      <p className="pb-4">
        Ao se cadastrar na plataforma Vanna o usuário aceita essa Política de
        Privacidade e declara aceitação das regras e informações aqui descritas
        e autoriza assim a utilização e tratamento dos dados pela plataforma
        Vanna.
      </p>
      <h2 className="my-4 font-bold">Definições</h2>
      <ul className="pb-4">
        <li>
          - Banco de dados: conjunto estruturado de dados pessoais, estabelecido
          em um ou em vários locais, em suporte eletrônico ou físico;
        </li>
        <li>
          - Controlador de dados (ou proprietário): é a pessoa física, jurídica,
          administração pública ou qualquer outra entidade, associação ou
          organização com direitos, também em conjunto com outro controlador de
          dados, que toma decisões sobre os propósitos e os métodos de
          processamento de dados pessoais e os meios utilizados - incluindo
          medidas de segurança relativas ao funcionamento e ao uso deste site. O
          Controlador, a menos que seja especificado de outra forma, é o
          proprietário deste site. Nesta política, a Vanna poderá ser
          denominado simplesmente como Controlador;
        </li>
        <li>
          - Usuário: pessoas que realizaram cadastro visando obter os serviços e
          funcionalidades prestados pela Plataforma Vanna e sua empresa
          Vanna, podendo ser denominado simplesmente como Titular;
        </li>
        <li>
          - Cookies: são pequenos arquivos de textos enviados para seu
          dispositivo, computador ou celular, pelo portal que você utiliza. Eles
          são armazenados e utilizados para recolher informações sobre sua
          visita, como configurações utilizadas e preferências. Dessa forma, é
          possibilitada uma navegação mais simples e personalizada, eliminando a
          necessidade de informar os mesmos dados repetidas vezes;
        </li>
        <li>
          - Vanna: empresa responsável pelo desenvolvimento e manutenção
          da plataforma Vanna;
        </li>
        <li>
          - Dado anonimizado: é o dado relativo ao titular que não permite sua
          identificação, considerando a utilização de meios técnicos razoáveis e
          disponíveis na ocasião de seu tratamento;
        </li>
        <li>
          - Dado pessoal: informação relacionada à pessoa natural identificada
          ou identificável;
        </li>
        <li>
          - Endereço IP: a sigla IP significa Internet Protocol. Refere-se a um
          número exclusivo atribuído a cada computador por um protocolo de
          internet. Sua função é identificar um computador em uma rede;
        </li>
        <li>
          - Logs: registros de atividade dos Usuários em site, plataforma
          integrativa, aplicativos e demais serviços prestados pelo Controlador;
        </li>
        <li>
          - Tratamento de dados: toda operação realizada com dados pessoais,
          como as que se referem à coleta, produção, recepção, classificação,
          utilização, reprodução, transmissão, distribuição, eliminação,
          avaliação, ao acesso, processamento, arquivamento, armazenamento ou
          controle da informação, modificação, comunicação, transferência,
          difusão ou extração;
        </li>
      </ul>
      <h2 className="my-4 font-bold">Disposições Gerais</h2>
      <p className="pb-4">
        A presente Política de Privacidade poderá ser atualizada ou modificada a
        qualquer tempo pelo Controlador para atingir as finalidades do negócio,
        como também para ficar em conformidade com a legislação, normas de
        reguladores ou requisição/decisão judicial.
      </p>
      <p className="pb-4">
        Na hipótese de atualização ou modificação, será encaminhada ao Usuário a
        nova versão para o e-mail por ele cadastrado, e as disposições da nova
        Política serão vinculadas ao Usuário a partir do envio do e-mail. É de
        responsabilidade do Usuário verificar sua caixa de e-mail. A Política de
        Privacidade atualizada ou modificada, além de ser encaminhada via
        e-mail, ficará disponível publicamente no site do Controlador. Caso não
        concorde com as novas disposições estabelecidas, será possível a
        revogação de seu consentimento, dadas as circunstâncias dessa Política
        de Privacidade.
      </p>

      <p className="pb-4">
        O contato do Encarregado/DPO é o privacidade@astrocoders.com. O Usuário
        deverá entrar em contato neste endereço em caso de solicitações para
        exclusão de mailing, restrição quanto ao processamento ou transferência
        de dados pessoais, ou para apresentar qualquer dúvida com relação às
        disposições constantes nesta Política de Privacidade.
      </p>

      <p className="pb-4">
        Caso empresas terceirizadas, parceiros e integradores realizem o
        processamento de quaisquer dados coletados pelo Controlador, deverão
        respeitar as condições aqui estipuladas e garantir níveis elevados de
        segurança nos patamares da Política de Segurança da Informação do
        Controlador.
      </p>
      <p className="pb-4">
        Sendo declarada a nulidade ou inaplicabilidade de alguma disposição da
        presente política por autoridade competente, as demais disposições
        permanecerão em pleno vigor.
      </p>

      <h2 className="my-4 font-bold">Controlador de dados e proprietário</h2>

      <p className="pb-4">
        Vanna Gestão de Pagamentos LTDA - contact@astrocoders.com - Rua Sergipe
        248, apto 51, Sorocaba, SP, Brasil CEP: 18035-560
      </p>

      <h2 className="my-4 font-bold">Modo e local de processamento de dados</h2>

      <p className="pb-4">
        O Controlador processa os dados dos Usuários de forma adequada e em
        conformidade com procedimentos de segurança, controle de acessos e em
        estrito cumprimento aos fins do contrato.
      </p>

      <p className="pb-4">
        O Controlador de dados processa os dados de Usuários de forma adequada e
        toma as medidas de segurança necessárias para impedir o acesso, a
        divulgação, alteração ou destruição não autorizada dos dados.
      </p>

      <p className="pb-4">
        O processamento de dados é realizado utilizando computadores e/ou
        ferramentas habilitadas, seguindo procedimentos organizacionais e meios
        estritamente relacionados com os fins indicados. Além do Controlador, em
        alguns casos, os dados podem ser acessados por certos tipos de pessoas
        envolvidas com a operação do site (administração, vendas, marketing,
        administração legal do sistema) ou pessoas externas (fornecedores
        terceirizados de serviços técnicos, carteiros, provedores de hospedagem,
        empresas de TI, agências de comunicação) nomeadas, quando necessário,
        como operadores de dados por parte do proprietário.
      </p>

      <p className="pb-4">
        Os dados são processados nas sedes e filiais de operação do Controlador
        ou em quaisquer outros lugares onde as partes envolvidas com o
        processamento estejam localizadas. Os dados serão acessados somente por
        pessoas autorizadas e em estrita obediência aos princípios da
        finalidade, adequação, necessidade, segurança e outros mais consagrados
        na legislação de proteção aos dados pessoais.
      </p>

      <h2 className="my-4 font-bold">
        Bases jurídicas para tratamento dos dados pessoais coletados
      </h2>

      <p className="pb-4">
        Os dados pessoais coletados são tratados pelo produta Vanna ou pela
        Vanna de acordo às bases legais expostas no artigo 7º da Lei
        13.709/18 (Lei Geral de Proteção de Dados - “LGPD”), que são hipóteses
        em que o tratamento de dados pessoais é permitido.
      </p>

      <p className="pb-4">
        O Controlador poderá coletar dados pessoais de diversas maneiras, por
        meio do acesso do Usuário a sua conta na Plataforma Vanna (site),
        durante o acesso via visita ao site do Controlador. Os dados coletados
        pelo Controlador poderão ser tratados em atendimento às seguintes bases
        legais:
      </p>

      <ol className="pb-4">
        <li>
          Mediante consentimento do Titular, hipótese em que ele manifesta estar
          de acordo com o tratamento e sua respectiva finalidade ou finalidades;
        </li>
        <li>
          Para a realização de estratégias de venda, melhoria dos serviços e
          produtos a serem oferecidos ao Titular em atenção, ainda, ao legítimo
          interesse do Controlador;
        </li>
        <li>
          Para cumprir alguma obrigação legal ou regulatória a que o Controlador
          esteja submetido ou para promover a defesa do Controlador em juízo e
          instâncias administrativas;
        </li>
        <li>
          Em casos de suspeita de fraudes, violação de direitos, para fins de
          investigação por autoridades competentes e mediante determinação
          judicial;
        </li>
        <li>
          Envio de publicidade nas hipóteses em que o Usuário manifestou
          interesse em receber;
        </li>
        <li>
          Envio de informações sobre serviços, recomendações de segurança,
          alterações de layouts;
        </li>
        <li>
          Para a proteção do crédito observados os limites legais, e para
          Cumprimento de Contrato e/ou Termos e Condições de Uso que o Usuário
          aderiu junto ao Controlador.
        </li>
      </ol>

      <h2 className="my-4 font-bold">
        Coleta dos dados pessoais e finalidade do tratamento
      </h2>

      <p className="pb-4">
        Os dados pessoais inseridos voluntariamente pelo Titular ou coletados de
        forma anônima pelo Controlador são classificados de acordo com a
        finalidade identificada para o seu tratamento e podem ser compartilhados
        para efetivação do Contrato de Prestação de Serviços ao qual o Titular
        aderiu. Os cookies que são coletados de forma anônima podem ser
        desabilitados pelos Titulares mediante configuração no seu navegador.
      </p>
      <p className="pb-4">
        Os dados são coletados quando o próprio Usuário os insere de forma
        voluntária no site, aplicativo e serviços disponibilizados pelo
        Controlador. Quando houver formalização do cadastro na Plataforma Vanna,
        durante a navegação no site, no aplicativo ou plataforma, por exemplo.
      </p>
      <p className="pb-4">
        Ocorre a coleta de dados, de forma anônima, quando capturados por meio
        de cookies de navegação.
      </p>
      <p className="pb-4">
        Há a coleta de dados também quando o Usuário informa dados de terceiros,
        hipótese em que o Usuário declara ter obtido as autorizações necessárias
        com o Titular dos dados. São englobados, nesta hipótese, os parceiros
        que integram a plataforma Vanna.
      </p>
      <p className="pb-4">
        O Usuário está ciente e concorda com a coleta, armazenamento,
        tratamento, processamento e uso das informações enviadas e/ou
        transmitidas pelo Usuário conforme estabelecido nesta Política.
      </p>
      <p className="pb-4">
        O Controlador utiliza cookies e identificadores anônimos para controle
        de audiência, navegação, segurança e publicidade, sendo que o Usuário
        concorda com essa utilização ao aceitar essa Política de Privacidade. Os
        cookies utilizados podem ser classificados como:
      </p>
      <i className="pb-4">Quanto à validade:</i>
      <ul className="pb-4">
        <li>
          - Sessão: são temporários e permanecem até que a página web ou o
          navegador sejam fechados. Podem ser utilizados para analisar padrões
          de tráfego na internet e para proporcionar melhor experiência e
          conteúdo contextualizado aos Usuários;
        </li>
        <li>
          - Permanente: persistem mesmo que o navegador tenha sido fechado.
          Podem ser utilizados para lembrar informações de login e senha dos
          Usuários, por exemplo, ou para garantir uma melhor experiência ao
          Usuário entre diferentes sessões.
        </li>
      </ul>

      <i className="pb-4">Quanto à propriedade:</i>
      <ul className="pb-4">
        <li>
          - Próprios: são de titularidade do Controlador, que possui controle
          sobre os cookies;
        </li>
        <li>
          - Terceiros: são de titularidades de terceiros, mas incluídos no
          dispositivo dos Usuários, mediante os sites, aplicativos e serviços
          oferecidos pelo Controlador.
        </li>
      </ul>
      <i className="pb-4">Quanto à finalidade:</i>
      <ul className="pb-4">
        <li>
          - Estritamente necessários: essenciais para permitir ao Usuário
          utilizar os sites, aplicativos e serviços oferecidos pelo Controlador,
          os quais não poderiam ser corretamente oferecidos sem esses cookies.
          São, geralmente, de sessão e próprios.
        </li>
        <li>
          - Performance: coletam informações anônimas sobre como os Usuários
          utilizam e interagem com os sites, aplicativos e serviços oferecidos
          pelo Controlador, permitindo reconhecer seus perfis e contabilizar as
          visitas e interações. São, geralmente, próprios.
        </li>
        <li>
          - Funcionalidade: permitem ao Controlador lembrar escolhas feitas
          pelos Usuários (login e localização) e proporcionam experiências mais
          pessoais, além de possibilitar eventuais customizações, quando
          disponibilizadas. Essas informações podem ser anonimizadas e não
          rastreiam atividades fora dos sites, aplicativos e serviços oferecidos
          pelo Controlador. São, geralmente, próprios e permanentes.
        </li>
        <li>
          - Analíticos e de publicidade: permitem aos anunciantes do Controlador
          entregar anúncios e informações mais relevantes aos Usuários. Também
          são utilizados para limitar o número de vezes em que o Usuário
          visualiza determinados anúncios e medir a efetividade de campanhas
          publicitárias. Eles lembram determinadas preferências dos Usuários e
          são utilizados para auxiliar a traçar seus perfis, visando melhorar a
          experiência do Usuário. São, geralmente, permanentes e podem ser de
          terceiros (como o Google Analytics).
        </li>
        <li>
          - Mídias sociais: permitem ao Usuário se conectar com mídias sociais,
          como LinkedIn, Twitter, Facebook, Pinterest e Instagram. São,
          geralmente, permanentes e de terceiros.
        </li>
        <li>
          - Segurança: ajudam o Controlador a monitorar atividades fraudulentas
          e proteger os dados do Usuário em acessos não autorizados. São,
          geralmente, permanentes e de terceiros.
        </li>
      </ul>

      <p className="pb-4">
        Os dados coletados pelo Controlador podem incluir, mas não se limitam a:
      </p>
      <p className="pb-4">
        Dados de Cadastro: a coleta de tais dados tem por finalidade a
        identificação, individualização e autorização do Usuário da Plataforma
        Vanna, como: nome, CPF/CNPJ, telefone, endereço etc.
      </p>
      <p className="pb-4">
        Dados de Contato: para utilizar os serviços prestados pelo Controlador,
        o Usuário deverá cadastrar seus dados de contato para viabilizar alguns
        processos de validação, ferramentas de gestão e manter o Usuário
        informado de mudanças, melhorias, procedimentos etc. São utilizados para
        envio de publicidade e newsletter, mediante consentimento do Usuário.
        Para atender a tais finalidades, o Usuário deve cadastrar o seu número
        de telefone e e-mail, sendo a atualização de tais dados de inteira
        responsabilidade do Usuário.
      </p>
      <p className="pb-4">
        Dados de Terceiros: o Usuário assume total responsabilidade pelos dados
        pessoais de terceiros publicados e/ou compartilhados por meio de sites,
        aplicativos e serviços oferecidos pelo Controlador, e confirma que tem o
        consentimento da parte terceira para fornecer dados para o Controlador.
        Por se tratar de uma plataforma de cobrança, é necessário que o Usuário
        da plataforma Vanna insira, nos sistemas, os dados de seus clientes,
        como: nome, endereço, telefone, e-mail etc.
      </p>
      <p className="pb-4">
        Dados internos da Conta: com a finalidade de proteger os Usuários da
        plataforma Vanna, visando atender suas solicitações, dúvidas e
        reclamações, tendo como amparo a Lei 8.078/1990 e o Decreto n°
        6.523/2008, os tickets criados pelos Usuários, as trocas de tickets de
        suporte e ligações são coletados e arquivados pela Vanna, de
        acordo com o legítimo interesse do Controlador e demais determinações
        legais mencionadas.
      </p>
      <p className="pb-4">
        Dados bancários: para realização de transferência dos valores recebidos
        na plataforma Vanna, é necessário que o Usuário da plataforma
        proceda com o cadastro de uma chave Pix CNPJ, podendo ser entendido como
        dados de pagamento. O Controlador se compromete a resguardar o sigilo
        financeiro e bancário dos Usuários, conforme legislação aplicável.
      </p>
      <p className="pb-4">
        Logs e Manutenção do Sistema: para fins de operação e manutenção, este
        site, e qualquer serviço de terceiros, poderá coletar arquivos que
        gravam a interação com este site (Logs do Sistema) ou usar, para este
        fim, outros dados pessoais (tal como endereço IP).
      </p>
      <p className="pb-4">
        Dados de localização (geolocalização): se utilizada, são coletados para
        auxiliar no processo de confirmação de informações cadastradas e/ou
        enviadas pelo Usuário acerca do seu endereço. É uma ferramenta utilizada
        para conferir maior segurança ao Usuário.
      </p>
      <p className="pb-4">
        Hábitos de navegação: são coletados para que a Vanna possa efetuar
        melhorias na plataforma integrativa e tornar a experiência do Usuário
        mais satisfatória.
      </p>

      <p className="pb-4">
        Ao acessar o site do Controlador, o Usuário concorda com a utilização de
        cookies em seu dispositivo ou, caso contrário, se compromete a ajustar
        as configurações de seu navegador para não permitir o uso - o que poderá
        limitar ou mesmo impedir a navegação, inviabilizando, por exemplo, o
        acesso à conta Vanna pelo site. Desta forma, recebemos algumas
        informações coletadas dos cookies, quando habilitadas, combinadas com
        outros dados fornecidos pelo Usuário.
      </p>
      <p className="pb-4">
        Os dados coletados poderão ser utilizados pelos colaboradores, parceiros
        e subcontratados do Controlador e pelas empresas pertencentes ao
        Controlador, estritamente para os fins estipulados nos Termos e
        Condições de Uso, para oferta e prestação de serviços complementares de
        cobrança, pagamento, crédito, investimentos ou quando permitidos
        legalmente.
      </p>
      <p className="pb-4">
        O Controlador poderá compartilhar os dados com parceiros estratégicos
        para viabilizar a prestação dos serviços aos Usuários.
      </p>
      <p className="pb-4">
        O Usuário também é responsável pela preservação dos seus dados pessoais,
        financeiros e bancários. Não incorre ao Controlador, no descumprimento
        desta Política, quando o compartilhamento dos dados ocorrerem pelo
        próprio Usuário ou quando a obtenção não autorizada por terceiros
        ocorrer por culpa não imputável ao Controlador.
      </p>
      <p className="pb-4">
        O Controlador poderá anonimizar os dados do Usuário, hipótese em que
        poderá coletar, armazenar, compartilhar e realizar quaisquer outros
        tipos de tratamentos independentemente de autorização do Usuário, uma
        vez que não será possível identificá-lo.
      </p>
      <p className="pb-4">
        O Controlador utilizará os dados pessoais coletados estritamente para as
        finalidades para a qual recebeu o consentimento do Usuário ou nas
        hipóteses das demais bases legais já mencionadas nesta política. Havendo
        alteração da finalidade, o Usuário será informado.
      </p>

      <h2 className="my-4 font-bold">Armazenamento dos dados</h2>
      <p className="pb-4">
        Os dados coletados são armazenados de forma segura e com mecanismos de
        controle. O Controlador poderá armazenar os dados em local físico
        (servidores) ou utilizar tecnologia de computação em nuvem, isto é,
        utilizando-se de servidores de terceiros.
      </p>
      <p className="pb-4">
        Os dados coletados são armazenados em local seguro e dotado de
        mecanismos de controle, no entanto, não se pode afirmar que exista algum
        sistema de segurança imune a falhas. Dessa forma, o Controlador se exime
        de toda e qualquer responsabilidade por danos e/ou prejuízos ocasionados
        por falhas, vírus, invasões de bancos de dados correspondentes aos
        serviços prestados, exceto quando comprovadamente tiver concorrido com
        dolo ou culpa.
      </p>
      <p className="pb-4">
        O Controlador poderá armazenar os dados coletados em servidores próprios
        ou de terceiro contratado para esta finalidade, podendo ser servidores
        físicos, tecnologia terceirizada ou outra que vier a ser desenvolvida
        futuramente, com localização no Brasil ou no exterior, o que enseja,
        neste último caso, transferência ou processamento dos dados fora do
        Brasil.
      </p>
      <p className="pb-4">
        O Controlador tomará precauções para que os dados sejam armazenados em
        locais com padrões elevados de segurança e em conformidade com a
        legislação referente à proteção de dados pessoais.
      </p>

      <h2 className="my-4 font-bold">Direitos do usuário</h2>
      <p className="pb-4">
        O Usuário poderá requerer a correção, exibição e exclusão de seus dados.
        Sempre quando houver alguma solicitação desse tipo, o Controlador
        manifestará sobre possíveis prejuízos na prestação dos serviços ou sobre
        alguma disposição legal/regulatória, contratual ou preservação de
        direitos que o impede de atender.
      </p>
      <p className="pb-4">
        O Usuário, de acordo com o Capítulo III da Lei Geral de Proteção de
        Dados, poderá, a qualquer momento, exercer seus direitos de petição, de
        oposição, de confirmação do tratamento e de acesso aos seus dados
        pessoais, podendo requerer informações sobre o tratamento dos dados
        pessoais coletados.
      </p>
      <p className="pb-4">
        O Usuário poderá solicitar a correção de dados incompletos, inexatos ou
        desatualizados, a adição, a anonimização, o bloqueio ou a eliminação de
        seus dados a qualquer tempo, bastando entrar em contato com o
        Controlador que especificará, quando se tratar de anonimização, a
        viabilidade ou não da ação, bem como os eventuais prejuízos na prestação
        dos serviços que poderão sobrevir. A atualização dos dados cadastrados é
        de responsabilidade do Usuário, portanto, qualquer inexatidão e/ou
        incorreção são de sua responsabilidade. No entanto, o Usuário poderá
        utilizar as ferramentas disponibilizadas pelo Controlador para promover
        a adição, correção e atualização de seus dados ou entrar em contato para
        obter maiores informações sobre como proceder.
      </p>
      <p className="pb-4">
        O Controlador se responsabilizará, quando ocorrer o uso compartilhado de
        dados, pela informação imediata dos agentes de tratamento sobre a
        correção, a eliminação, a anonimização ou o bloqueio dos dados, para que
        repitam idêntico procedimento.O Usuário poderá recusar a dar seu
        consentimento ao Controlador para a coleta de alguns dados, hipótese em
        que o Controlador não poderá garantir o perfeito funcionamento do site,
        aplicativos e serviços prestados. O Usuário poderá, ainda, revogar o
        consentimento antes concedido, podendo, neste caso, ter a suspensão ou
        operabilidade do serviço prejudicada. A revogação aqui disposta surtirá
        efeito para coletas futuras.
      </p>
      <p className="pb-4">
        O Usuário poderá requerer a portabilidade dos seus dados a outro
        fornecedor de serviço ou produto, mediante requisição expressa, de
        acordo com a regulamentação da autoridade nacional, observados os
        segredos comercial e industrial.
      </p>
      <p className="pb-4">
        O Usuário também poderá requerer, a qualquer momento, a exclusão dos
        seus dados, e o Controlador poderá deixar de excluí-los para preservar
        direitos próprios ou de terceiros, para cumprir disposição legal,
        determinação judicial e, quando forem necessários, para realização de
        auditorias ou para cumprimento dos Contratos e Termos de Uso
        formalizados junto ao Usuário.
      </p>
      <p className="pb-4">
        O Usuário poderá, a qualquer tempo, entrar em contato com o Controlador,
        para solicitar informações sobre o tratamento e o compartilhamento
        dispensado aos seus dados, bem como para requerer esclarecimentos acerca
        da Política de Privacidade. As informações solicitadas serão
        disponibilizadas em até 15 dias depois da solicitação realizada via o
        email contact@astrocoders.com.
      </p>

      <h2 className="my-4 font-bold">Escolhas do usuário</h2>
      <p className="pb-4">
        O Usuário poderá desabilitar cookies e cancelar comunicações
        relacionadas à marketing, de acordo com sua preferência, observadas as
        limitações previstas nesta Política.
      </p>
      <p className="pb-4">
        O Usuário poderá gerenciar seu consentimento para utilização de cookies,
        mediante a configuração de seu navegador, levando em consideração que os
        cookies são utilizados para viabilizar e melhorar o uso e as
        funcionalidades das plataformas do Controlador.
      </p>
      <p className="pb-4">
        O Usuário poderá cancelar a subscrição para recebimento de comunicações
        relacionadas à marketing a qualquer momento, bastando acessar os links
        disponíveis nas comunicações.
      </p>
      <p className="pb-4">
        O cancelamento da subscrição das comunicações de marketing não cancelará
        o recebimento das comunicações administrativas e demais anúncios não
        relacionados à marketing.
      </p>

      <h2 className="my-4 font-bold">Exclusão dos dados</h2>
      <p className="pb-4">
        A exclusão de dados ocorre quando a finalidade já foi atingida, para
        cumprimento da legislação e regulamentação vigente ou quando solicitado
        pelo Usuário, nas hipóteses em que é possível a exclusão.
      </p>
      <p className="pb-4">
        As informações coletadas pelo Controlador serão automaticamente
        excluídas de seus servidores quando os fins para os quais foram
        coletadas deixarem de ser úteis, bem como quando o usuário solicitar a
        eliminação de seus dados pessoais.
      </p>
      <p className="pb-4">
        Contudo, ressalta-se que as informações poderão ser conservadas quando
        em:
      </p>
      <ul className="pb-4">
        <li>- Cumprimento de obrigação legal ou regulatória;</li>
        <li>
          - Transferência a terceiro, respeitando os requisitos do tratamento de
          dados;
        </li>
        <li>
          - Uso exclusivo do Controlador, sem acesso de terceiros e com os dados
          anonimizados;
        </li>
        <li>
          - Demais hipóteses previstas no artigo 16 da Lei 13.709/18 (Lei Geral
          de Proteção de Dados - “LGPD”) que justifiquem tal fim.
        </li>
      </ul>

      <h2 className="my-4 font-bold">
        Modificações nas políticas de privacidade
      </h2>
      <p className="pb-4">
        As políticas de privacidade poderão ser alteradas a qualquer momento e
        por qualquer motivo. Um aviso via e-mail será enviado para o Usuário
        responsável com a data para entrada em vigor das alterações. Ao
        continuar com a manutenção do uso do produto, o Usuário aceita as
        alterações dos termos de uso. Caso não haja pendências, poderá solicitar
        o cancelamento e remoção de seus dados da plataforma se não concordar
        com as mudanças estabelecidas.
      </p>

      <h2 className="my-4 font-bold">Foro</h2>
      <p className="pb-4">
        O foro eleito para resolver qualquer problema é o Foro da Comarca de
        Sorocaba, Estado de São Paulo, com renúncia expressa a qualquer outro,
        por mais privilegiado que seja este.
      </p>
      <h2 className="my-4 font-bold">Contato</h2>
      <p className="pb-4">
        Você pode entrar em contato através do email oi@vanna.app para saber
        mais sobre nosso produto ou tirar dúvidas sobre os termos e disposições
        aqui descritos.
      </p>
    </section>
  </Layout>
)

export default Privacy
