import React from 'react'
import { Link } from 'gatsby'

interface LinksProps {
  className?: string
  style?: React.CSSProperties
}

export const Links = ({ className = '', style = {} }: LinksProps) => (
  <div className={'w-full ' + className} style={style}>
    <div
      className="py-5 px-4 flex flex-nowrap whitespace-nowrap space-x-10 overflow-x-auto lg:justify-center lg:mx-auto lg:space-x-20 text-white"
      style={{ backgroundColor: 'rgb(25, 25, 50)' }}
    >
      <Link
        to="/funcionalidades"
        activeStyle={{ textDecoration: 'underline', color: '#d6c61d' }}
        className="text-white cursor-pointer font-semibold whitespace-nowrap"
      >
        Funcionalidades
      </Link>
      <Link
        to="/tarifas"
        activeStyle={{ textDecoration: 'underline', color: '#d6c61d' }}
        className="text-white cursor-pointer font-semibold whitespace-nowrap"
      >
        Tarifas
      </Link>
      <Link
        to="/termos-uso"
        activeStyle={{ textDecoration: 'underline', color: '#d6c61d' }}
        className="text-white cursor-pointer font-semibold whitespace-nowrap"
      >
        Termos de uso
      </Link>
      <Link
        to="/politica-privacidade"
        activeStyle={{ textDecoration: 'underline', color: '#d6c61d' }}
        className="text-white cursor-pointer font-semibold whitespace-nowrap"
      >
        Política de privacidade
      </Link>
      <Link
        to="/seguranca"
        activeStyle={{ textDecoration: 'underline', color: '#d6c61d' }}
        className="text-white cursor-pointer font-semibold whitespace-nowrap pr-6"
      >
        Segurança
      </Link>
      {/* <a
        href="https://status.vanna.app"
        className="text-white cursor-pointer font-semibold whitespace-nowrap"
      >
        Status
      </a> */}
    </div>
  </div>
)
